<template>
	<MhRouterView class="DevView view" :isContentReady="isContentReady">

		<div class="view__inner hSpace hSpace--content">

			<div style="grid-column: span 4">
				<br /><strong>DevView.vue</strong><hr /><br />

				<mark>Font Samples</mark><br/><br/>

				<div class="DevView__fontSamples">
					<div class="font font--sans font--bold font--sizeMedium">
						Wohnen 25/25<br/>
						font font--sans font--bold font--sizeMedium
					</div>
					<br>
					<div class="font font--mono font--sizeDefault">
						Kategorie 25/25<br/>
						font font--mono font--sizeDefault
					</div>
					<br>
					<div class="font font--sans font--bold font--sizeBig">
						Hier steht ein Titel, hier steht ein Titel, es steht 60/60<br/>
						font font--sans font--bold font--sizeBig
					</div>
					<br>
					<div class="font font--sans font--bold font--sizeMedium">
						Thu, 26 Nov 2020 - 13:30 (CET)
						Conference Website & RegistrationFull URP 2020 Conference Programme
						Details on the sessions: https://urp2020.plazz.net/ <br/>
						font font--sans font--bold font--sizeMedium
					</div>
					<br>
					<div class="font font--sans font--bold font--sizeMedium">
						Zwischentitel 25/30<br/>
						font font--sans font--bold font--sizeMedium
					</div>
					<div class="font font--sans font--bold font--sizeDefault">
						Pudit velit, volliquiOccullabora dit ut estrum et plandis erumquate eum non cores aut isi dolupta simolecaerro doluptaturit laut aut fugias sintios dolorei ctaturiatet eum, autet doluptatet accus dolorro renitius sundit lab is soloratiorum et restio.
						20/25 <br/>
						font font--sans font--bold font--sizeDefault
					</div>
					<br/>
					<div class="font font--sans font--bold font--sizeDefault">
						Team Member Name
						20/25 <br/>
						font font--sans font--bold font--sizeDefault
					</div>
					<div class="font font--mono font--sizeSmall">
						Technische Universität Berlin
						16/19<br/>
						font font--mono font--sizeSmall
					</div>
					<br>
					<div class="font font--sans font--bold font--sizeLarge">
						Arbeit
						30/30 <br/>
						font font--sans font--bold font--sizeLarge
					</div>
					<div class="font font--sans font--bold font--sizeMini">
						<a class="">Prev</a> &emsp; <a class="">Next</a>
						14/14 <br/>
						font font--sans font--bold font--sizeMini
					</div>
					<br/>
					<div class="font font--mono font--sizeTiny">
						Small MainCategory
						12/12 <br/>
						font font--mono font--sizeTiny
					</div>
				</div>
			</div>

		</div>

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js' // TODO: remove this
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	import MhRouterView from './../components/MhRouterView/v2/MhRouterView.vue'

	export default {
		name: 'DevView',
		components: {
			MhRouterView,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {
		},
		data() {
			return {
				isContentReady: false,
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		mounted(){
			this.isContentReady = true
		},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DevView {}
	.DevView {
		&__fontSamples {
			& > * {
				outline: 1px solid fade( red, 50 );
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
